import React from 'react';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { BoxContractDetailView } from './BoxContractDetailView';
import BoxContractsListView from './BoxContractsListView';
import BoxOffersListView from './BoxOffersListView';
import DashboardTabs from '@/components/layout/DashboardTabs';
import ScopedRoute from '@/components/ScopedRoute';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';
import useAppState from '@/hooks/useAppState';

const CustomTabs = () => (
  <DashboardTabs
    title="BUY'N'RENT"
    tabs={[
      { name: 'Contracts', key: 'contracts' },
      { name: 'Offers', key: 'offers' },
    ]}
  />
);

const BuyNRentRouter = () => {
  const { pathname } = useLocation();
  const userScopes = useAppState((state) => state.scopes);

  return (
    <>
      {pathname.split('/').length <= 3 && <CustomTabs />}

      {pathname === `${appRoutes.BUY_N_RENT.ROOT}` && (
        <Navigate to={appRoutes.BUY_N_RENT.CONTRACTS} />
      )}
      <Routes>
        <Route
          path={`${appRoutes.BUY_N_RENT.CONTRACTS}/*`}
          element={
            <ScopedRoute
              component={<BoxContractsListView />}
              userScopes={userScopes}
              requiredScopes={[scopes.VIEW_BUY_N_RENT]}
            />
          }
        />
        <Route
          path={appRoutes.BUY_N_RENT.CONTRACTS_ID}
          element={
            <ScopedRoute
              component={<BoxContractDetailView />}
              userScopes={userScopes}
              requiredScopes={[scopes.VIEW_BUY_N_RENT]}
            />
          }
        />

        <Route
          path={appRoutes.BUY_N_RENT.OFFERS}
          element={
            <ScopedRoute
              component={<BoxOffersListView />}
              userScopes={userScopes}
              requiredScopes={[scopes.VIEW_BUY_N_RENT]}
            />
          }
        />
      </Routes>
    </>
  );
};

export default BuyNRentRouter;
