import React, { useMemo } from 'react';

import {
  FileTextOutlined,
  CheckCircleOutlined,
  EuroCircleOutlined,
  DropboxOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Row, Timeline, Col, Tooltip } from 'antd';
import { parseISO } from 'date-fns';
import styled from 'styled-components';

import EventsTable from './EventsTable';
import useGetStateEventHistoryShipment from '../hooks/useGetStateEventHistoryShipment';
import InlineSpacer from '@/components/layout/InlineSpacer';
import dates from '@/helpers/dates';

const { Item } = Timeline;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const WrapperTimeline = styled.div`
  height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 1.5rem;
`;

const WrapperLegend = styled.div`
  padding-bottom: 2rem;
`;

const chooseIcon = (eventType) => {
  switch (eventType) {
    case 'DELIVERY_STATUS':
      return <DropboxOutlined style={{ color: 'brown' }} />;
    case 'CONTRACT':
      return <FileTextOutlined style={{ color: 'blue' }} />;
    case 'CONFIRMATION':
      return <CheckCircleOutlined style={{ color: 'green' }} />;
    case 'PAYMENT':
      return <EuroCircleOutlined style={{ color: 'black' }} />;
  }
};

const ShipmentStateEventHistory = ({ id }) => {
  const events = useGetStateEventHistoryShipment(id);

  const Items = useMemo(() => {
    if (events.length) {
      return events.map((e, i) => {
        const icon =
          e.eventCause === 'BOX' && e.boxEventTime === null ? (
            <Tooltip title="The boxTimestamp is not available, so the backend consumeAt is used instead.">
              <WarningOutlined style={{ color: 'orange' }} />
            </Tooltip>
          ) : (
            chooseIcon(e.eventType)
          );

        const dateEvent =
          e.eventCause === 'BOX' &&
          e.boxEventTime !== null &&
          e.boxEventTime !== undefined
            ? dates.formatDate(parseISO(e.boxEventTime))
            : dates.parseDate(e.createdAt);

        return (
          <Item key={e.id} dot={icon} label={dateEvent}>
            <div style={i === 0 ? { fontSize: '20px' } : { fontSize: '15px' }}>
              {e.eventName}
            </div>
            cause: {e.eventCause}
          </Item>
        );
      });
    }

    return <p>No events.</p>;
  }, [events]);

  const Legend = useMemo(() => {
    if (events.length) {
      return (
        <WrapperLegend>
          {chooseIcon('DELIVERY_STATUS')} <InlineSpacer width="0.7rem" />
          DELIVERY_STATUS <InlineSpacer width="2rem" />
          {chooseIcon('CONTRACT')} <InlineSpacer width="0.7rem" /> CONTRACT
          <InlineSpacer width="2rem" />
          {chooseIcon('CONFIRMATION')} <InlineSpacer width="0.7rem" />
          CONFIRMATION <InlineSpacer width="2rem" />
          {chooseIcon('PAYMENT')} <InlineSpacer width="0.7rem" /> PAYMENT
          <InlineSpacer width="2rem" />
        </WrapperLegend>
      );
    }
  }, [events.length]);

  return (
    <Wrapper>
      {Legend}
      <Row>
        <Col flex="18">
          <WrapperTimeline>
            <Timeline mode="left">{Items}</Timeline>
          </WrapperTimeline>
        </Col>

        <Col flex="24">
          <EventsTable id={id} />
        </Col>
      </Row>
    </Wrapper>
  );
};
export default ShipmentStateEventHistory;
