import React, { useState } from 'react';

import { WarningOutlined } from '@ant-design/icons';
import { Grid } from '@lp/ds-next';
import { Tag, Typography } from 'antd';

import ShipmentDetailsContent from '../components/ShipmentDetailsContent';
import PageTitle from '@/components/layout/PageTitle';

const ShipmentDetailsView = () => {
  const { Text } = Typography;
  const [isRTS, setIsRTS] = useState(false);

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item mobile={11}>
        <PageTitle
          title={
            <>
              <Text
                style={{
                  fontWeight: '500',
                  fontSize: '2.25rem',
                }}
              >
                SHIPMENT DETAILS
              </Text>
              {isRTS && (
                <Tag color="#FFF4F0" style={{ marginLeft: '0.5rem' }}>
                  <WarningOutlined
                    style={{ color: '#9A4F25', fontSize: '1rem' }}
                  />
                  <Text
                    style={{
                      color: '#9A4F25',
                      fontWeight: '500',
                      fontSize: '0.75rem',
                      textTransform: 'initial',
                      letterSpacing: '0',
                    }}
                  >
                    Return to sender
                  </Text>
                </Tag>
              )}
            </>
          }
        />
      </Grid>
      <Grid item mobile={11}>
        <ShipmentDetailsContent setIsRTS={setIsRTS} />
      </Grid>
    </Grid>
  );
};
export default ShipmentDetailsView;
