import React, { useCallback, useEffect, useState } from 'react';

import { Select, SelectProps } from 'antd';

import useSearchPartner from '../features/lp-village/partners/hooks/useSearchPartner';

interface PartnerAutocompleteProps {
  reactKey?: string | number;
  placeholder?: string;
  style?: {};
  updateSearch: Function;
  isMultiple?: boolean;
  initialValue?: { label: string; value: string }[];
  name?: string;
}

const PartnerAutocomplete = ({
  reactKey,
  placeholder,
  style,
  updateSearch,
  isMultiple,
  initialValue = [],
  name,
}: PartnerAutocompleteProps) => {
  const { getMultiplePartner } = useSearchPartner();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectMessage, setSelectMessage] = useState<string>('');

  const [partnerOptions, setPartnerOptions] =
    useState<SelectProps['options']>(initialValue);
  const [currentSelectedValue, setCurrentSelectedValue] = useState<
    SelectProps['options']
  >(initialValue || []);

  // Reset currentSelectedValue when initialValue changes
  useEffect(() => {
    setCurrentSelectedValue(initialValue);
  }, [initialValue]);

  const handlePartnerSearch = useCallback(
    (searchValue: string) => {
      if (searchValue.length > 2) {
        // setIsLoading(true);

        getMultiplePartner(searchValue)
          .then(({ partners }) => {
            if (partners.length) {
              const options = partners.map((partner) => ({
                label: partner.name,
                value: partner.id,
              }));

              // merge current value selected whith new value filter and remove duplicate
              const mergeOption = [...options, ...currentSelectedValue!].filter(
                (opt, index, array) =>
                  index === array.findIndex((item) => item.value === opt.value)
              );

              setPartnerOptions(mergeOption);
              setIsLoading(false);
            }
          })
          .catch(() => {
            setSelectMessage(
              'No result or research returns too many values. Please be more specific'
            );
          });
      }
    },
    [currentSelectedValue, getMultiplePartner]
  );

  const handleUpdate = (selectedValues) => {
    if (
      !selectedValues ||
      (Array.isArray(selectedValues) && selectedValues.length === 0)
    ) {
      setCurrentSelectedValue([]);
      updateSearch([]);

      return;
    }

    const valuesArray = Array.isArray(selectedValues)
      ? selectedValues
      : [selectedValues];

    const updatedValues = valuesArray.map((opt) => ({
      label: opt.label,
      value: opt.value,
    }));
    setCurrentSelectedValue(updatedValues);
    updateSearch(updatedValues);
  };

  return (
    <Select
      data-testid={name}
      key={reactKey}
      value={currentSelectedValue}
      allowClear
      mode={isMultiple ? 'multiple' : undefined}
      placeholder={placeholder}
      style={style}
      options={partnerOptions}
      maxTagCount={1}
      filterSort={(a, b) => {
        if (a.label! < b.label!) {
          return -1;
        }
        if (a.label! > b.label!) {
          return 1;
        }

        return 0;
      }}
      labelInValue
      onSearch={(newValue) => handlePartnerSearch(newValue)}
      onSelect={(_, option) => {
        setCurrentSelectedValue(
          currentSelectedValue?.concat({
            label: option.label,
            value: option.value,
          })
        );
      }}
      onChange={(v) => {
        handleUpdate(v);
      }}
      loading={isLoading}
      showSearch
      filterOption={false}
      optionFilterProp="value"
      notFoundContent={<p>{selectMessage}</p>}
    />
  );
};
export default PartnerAutocomplete;
