import React from 'react';

import { Result } from 'antd';

import { TimeLineRow } from '../../shipments/components/TimeLineRow';
import { boxLifecycle } from '../models/boxLifecycle';

export const BoxLifecycleHistory = ({ lifecycleStatuses }) => {
  const noData = lifecycleStatuses.length === 0;

  if (noData) {
    return <Result status="info" title="No lifecycle statuses." />;
  }

  return (
    <div data-testid="lifecycle-timelines">
      {lifecycleStatuses.map((item: boxLifecycle, index: number) => (
        <TimeLineRow key={item.id} timeLineEvent={item} index={index} />
      ))}
    </div>
  );
};
