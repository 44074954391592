import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  ReloadOutlined,
  CloseOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { Grid } from '@lp/ds-next';
import { Tag, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import styled from 'styled-components';

import useServiceLevelsStore, {
  IServiceLevelEntry,
} from '../../serviceLevels/hooks/useServiceLevelsStore';
import CreateShipment from '../components/CreateShipment';
import GenerateBillingReport from '../components/GenerateBillingReport';
import useGetShipments from '../hooks/useGetShipments';
import useShipmentsStore, {
  TShipmentsSortBy,
} from '../hooks/useShipmentsStore';
import { CONTRACT_STATES } from '../models/shipment';
import BillingServerSearch from '@/components/BillingServerSearch';
import PageTitle from '@/components/layout/PageTitle';
import PillButton from '@/components/PillButton';
import ServerSearch from '@/components/ServerSearch';
import StyledTable from '@/components/tables/StyledTable';
import TablesTotals from '@/components/TablesTotals';
import { scopes } from '@/config';
import { useServiceLevels } from '@/features/serviceLevels';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import useAppState from '@/hooks/useAppState';
import useWindowSize from '@/hooks/useWindowSize';
import { ShipmentsTotalContext } from '@/shared/providers/shipmentsTotalProvider';

const Wrapper = styled.div<{ width?: string; mb?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${({ mb }) => (mb ? mb : 0)};
  width: ${({ width }) => width};
`;

const ShipmentsListView = () => {
  const userScopes = useAppState((state) => state.scopes);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [billingMode, setBillingMode] = useState<boolean>(false);
  const [billingWindow, setBillingWindow] = useState([]);
  const sTotal = useContext(ShipmentsTotalContext);
  const refreshTimeout = useRef<NodeJS.Timeout | null>(null);
  const loading = useShipmentsStore((state) => state.loading);
  const pageSize = useShipmentsStore((state) => state.pageSize);
  const offset = useShipmentsStore((state) => state.offset);
  const total = useShipmentsStore((state) => state.total);
  const defaultSort = useShipmentsStore((state) => state.defaultSort);
  const dispatch = useShipmentsStore((state) => state.dispatch);
  const searchTerms = useShipmentsStore((state) => state.searchTerms);
  const rts = useShipmentsStore((state) => state.rts);
  const contractState = useShipmentsStore((state) => state.contractState);
  const windowSize = useWindowSize();
  const { result: serviceLevelsList } = useServiceLevels();
  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  const { Text } = Typography;

  const setServiceLevels = useServiceLevelsStore(
    (state) => state.setServiceLevels
  );
  useGetShipments();

  // replace id by name of te service level for each line of the table
  const rowData = useShipmentsStore((state) => state.rowData);

  if (serviceLevelsList.state === 'success') {
    const mappedServiceLevels: Array<IServiceLevelEntry> =
      serviceLevelsList.resource.items.map((level) => ({
        id: level.id,
        name: level.name,
      }));
    setServiceLevels(mappedServiceLevels);
    rowData.forEach((item) => {
      serviceLevelsList.resource.items.forEach((elt) => {
        if (elt.id === item.serviceLevel) {
          item.serviceLevel = elt.name;
        }
      });
    });
  }

  const handleRefresh = useCallback(() => {
    if (refreshTimeout.current) {
      clearTimeout(refreshTimeout.current);
    }
    refreshTimeout.current = setTimeout(() => {
      dispatch({
        type: 'refreshTable',
      });
    }, 100);
  }, [dispatch]);

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      if (refreshTimeout.current) {
        clearTimeout(refreshTimeout.current);
      }

      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as TShipmentsSortBy, order: sortOrder },
      });

      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });

      dispatch({
        type: 'updateFilters',
        args: {
          contractState: isArray(get(filters, 'contractState'))
            ? filters.contractState
            : null,
          deliveryStatus: filters.deliveryStatus || null,
        },
      });

      refreshTimeout.current = setTimeout(() => {
        dispatch({ type: 'refreshTable' });
        refreshTimeout.current = null;
      }, 100);
    },
    [dispatch]
  );

  const columnKeys = [
    'shipmentId',
    'updatedAt',
    'reference',
    'boxSerialNumber',
    'contractState',
    'recipientAndSender',
    'carrierTrackingCode',
    'carrierCode',
    'deliveryStatus',
  ];

  const onSelectChange = useCallback(
    (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    [setSelectedRowKeys]
  );

  const rowSelection = useMemo(
    () =>
      billingMode
        ? {
            selectedRowKeys,
            onChange: onSelectChange,
          }
        : null,
    [selectedRowKeys, onSelectChange, billingMode]
  );

  if (userScopes.includes(scopes.MANAGE_BILLING)) {
    columnKeys.push('isBillable');
  }

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: columnKeys,
    addDefaultColumns: false,

    columnsSpecialProps: {
      contractState: {
        filters: CONTRACT_STATES.map((r) => ({ text: r, value: r })),
        filterMultiple: true,
        filteredValue: contractState ? [contractState] : null,
        width: '6rem',
      },
      reference: {
        width: '10rem',
      },
      deliveryStatus: {
        filters: [
          { label: 'RTS', value: true },
          { label: 'Not RTS', value: false },
        ].map((d) => ({
          text: d.label,
          value: d.value,
        })),
        filterMultiple: false,
        width: '8rem',
        title: 'Return To Sender',
        filteredValue: rts ? [rts] : null,
        render: (_, record) =>
          record.deliveryStatus === 'RETURN_TO_SENDER' ? (
            <Tag color="#FFF4F0">
              <WarningOutlined style={{ color: '#9A4F25', fontSize: '1rem' }} />
              <Text
                style={{
                  color: '#9A4F25',
                  fontWeight: '500',
                  fontSize: '0.75rem',
                }}
              >
                Return to sender
              </Text>
            </Tag>
          ) : null,
      },
    },
    defaultSort: defaultSort,
    eyeLinkProps: { to: '/shipments' },
  });

  //hack to have all rows selected by default
  useEffect(() => {
    if (billingMode) {
      const newSelection: string[] = [];
      rowData.forEach((row) => {
        newSelection.push(row.id);
      });
      setSelectedRowKeys(newSelection);
    }
  }, [rowData, billingMode]);

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item mobile={11}>
        <PageTitle title="SHIPMENTS" />
      </Grid>
      <Grid item mobile={11}>
        <VStack>
          <HStack alignSelf="flex-end">
            <Wrapper>
              {userScopes.includes(scopes.MANAGE_BILLING) && (
                <GenerateBillingReport
                  billingMode={billingMode}
                  setBillingMode={setBillingMode}
                  billingWindow={billingWindow}
                  data={rowData}
                  selectedRows={selectedRowKeys}
                />
              )}
            </Wrapper>
            <Wrapper>
              <CreateShipment />
            </Wrapper>
          </HStack>
          {!billingMode && (
            <Wrapper>
              <HStack align="center">
                <ServerSearch
                  fields={[
                    { key: 'id', placeholder: 'Shipment-ID' },
                    { key: 'partnerId', placeholder: 'Partnership name' },
                    { key: 'reference', placeholder: 'Shipment name' },
                    {
                      key: 'recipientLastName',
                      placeholder: 'Recipient Last Name',
                    },
                    { key: 'recipientEmail', placeholder: 'Recipient Email' },
                    {
                      key: 'carrierTrackingCode',
                      placeholder: 'Carrier Reference Number',
                    },
                  ]}
                  dispatch={dispatch}
                  storeSearchTerms={searchTerms}
                />
              </HStack>
              <Wrapper mb="1rem">
                <PillButton
                  onClick={handleRefresh}
                  type="default"
                  icon={<ReloadOutlined />}
                ></PillButton>
              </Wrapper>
            </Wrapper>
          )}
          {billingMode && (
            <HStack alignSelf="flex-start">
              <BillingServerSearch
                fields={[
                  { key: 'id', placeholder: 'Shipment-ID' },
                  { key: 'partnerId', placeholder: 'Partner-ID' },
                  { key: 'reference', placeholder: 'Shipment name' },
                  { key: 'activeState' },
                  { key: 'range' },
                ]}
                dispatch={dispatch}
                storeSearchTerms={searchTerms}
                setBillingWindow={setBillingWindow}
              />
              {/* fix me: hacky layout */}
              <Wrapper>
                <VStack justify="space-around">
                  <Box h="5px"></Box>
                  <PillButton
                    onClick={handleRefresh}
                    type="default"
                    icon={<ReloadOutlined />}
                    data-testid="refresh-button"
                  />
                </VStack>
                <Box w="10px"></Box>
                <VStack justify="space-around">
                  <Box h="5px"></Box>
                  <PillButton
                    onClick={() => setBillingMode(false)}
                    type="default"
                    icon={<CloseOutlined />}
                    data-testid="close-billing-mode-button"
                  />
                </VStack>
              </Wrapper>
            </HStack>
          )}
        </VStack>
        <StyledTable
          data-testid="shipment-list"
          rowKey="id"
          dataSource={rowData}
          rowSelection={rowSelection}
          rowClassName={(record) =>
            record.deletedAt > 0
              ? 'red-bg'
              : record.originalShipmentId
                ? 'green-bg'
                : ''
          }
          columns={columns}
          size="small"
          pagination={pagination}
          loading={loading}
          scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
          onChange={handleTableChange}
          footer={() => (
            <TablesTotals
              total={total}
              gTotal={sTotal}
              selectedItems={selectedRowKeys.length}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ShipmentsListView;
