import React, { useCallback, useMemo, useState } from 'react';

import { HStack, VStack } from '@chakra-ui/react';
import { NewTextField as TextField, Stack, Text } from '@lp/ds-next';
import { Checkbox } from 'antd';
import { isEmpty, size } from 'lodash/fp';
import styled from 'styled-components';

import { useUpdateNote } from '../../api/createNote';
import { useUpdateProduct } from '../../api/updateProduct';
import useBoxesStore from '../../hooks/useBoxesStore';
import useBoxLifecycle from '../../hooks/useBoxLifecycle';
import useEditProduct from '../../hooks/useEditProduct';
import { LifeCycleTypeForm } from '../LifeCycleTypeForm';
import ProductTypeForm from '../ProductTypeForm';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import { SearchAccount } from '@/features/lp-account-users';
import { SearchPartner, SearchShop } from '@/features/lp-village';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const Wrapper = styled.div`
  margin-top: 2rem;
`;

interface IRelation {
  relationType: string;
  objectType: string;
  objectId: string;
}

const EditProductsModal = ({
  ids,
  showModal,
  setShowModal,
  setIsScrollTableTop,
}) => {
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);
  const [, makeRequest] = useImperativeRequestWrapper('device');

  const [partnerId, setPartnerId] = useState<string>('');
  const [shopId, setShopId] = useState<string>('');
  const [accountId, setAccountId] = useState<string>('');

  // Checkboxes
  const [showPIC, setShowPIC] = useState<boolean>(false);
  const [showHolder, setShowHolder] = useState<boolean>(false);
  const [showArticle, setShowArticle] = useState<boolean>(false);
  const [showLifeCycle, setShowLifeCycle] = useState<boolean>(false);
  const [showMBOM, setShowMBOM] = useState<boolean>(false);
  const [showMechanicalConfiguration, setShowMechanicalConfiguration] =
    useState<boolean>(false);
  const [showNote, setShowNote] = useState<boolean>(false);

  // Values
  const [articleCode, setArticleCode] = useState<IRelation | undefined>(
    undefined
  );
  const [picRelation, setPicRelation] = useState<IRelation | undefined>(
    undefined
  );
  const [holderRelation, setHolderRelation] = useState<IRelation | undefined>(
    undefined
  );
  const [lifeCycleStatus, setLifeCycleStatus] = useState<{
    status: string;
    sub_status?: string;
  }>();
  const [mbomVersion, setMbomVersion] = useState('');
  const [mechanicalConfiguration, setMechanicalConfiguration] = useState('');
  const [note, setNote] = useState('');
  // End values

  const dispatch = useBoxesStore((state) => state.dispatch);

  const { editProductType } = useEditProduct();
  const { mutateAsync: updateProduct } = useUpdateProduct();
  const { mutateAsync: createNote } = useUpdateNote();
  const { addLifecycleStatus } = useBoxLifecycle();

  const clearSelection = useCallback(() => {
    setPartnerId('');
    setShopId('');
    setAccountId('');

    // Checkboxes
    setShowPIC(false);
    setShowHolder(false);
    setShowArticle(false);
    setShowLifeCycle(false);
    setShowMBOM(false);
    setShowMechanicalConfiguration(false);
    setShowNote(false);

    // Values
    setPicRelation(undefined);
    setHolderRelation(undefined);
    setArticleCode(undefined);
    setLifeCycleStatus(undefined);
    setMbomVersion('');
    setMechanicalConfiguration('');
    setNote('');
  }, [setPartnerId]);

  const onPicCheckboxChange = useCallback(() => {
    if (showPIC) {
      setPartnerId('');
    }
    setShowPIC(!showPIC);
  }, [setPartnerId, setShowPIC, showPIC]);

  const onPicSelected = useCallback(
    (picId) => {
      if (picId)
        setPicRelation({
          relationType: 'PIC',
          objectId: picId,
          objectType: 'LP_PARTNER',
        });
    },
    [setPicRelation]
  );

  const handleAccountFound = useCallback(
    (accountId, source) => {
      if (accountId)
        setHolderRelation({
          relationType: 'HOLDER',
          objectId: accountId,
          objectType: source,
        });
    },
    [setHolderRelation]
  );

  const handleArticleSelected = useCallback(
    (code) => {
      if (code) setArticleCode(code);
    },
    [setArticleCode]
  );

  const createRelation = useCallback(
    async (body, id) => {
      const { error } = await makeRequest({
        path: `/boxes/${id}/relations`,
        method: 'post',
        body: body,
      });
      if (error) {
        addError(error);

        return error;
      }
    },
    [makeRequest, addError]
  );

  const handleOnCancel = useCallback(() => {
    clearSelection();
    setShowModal(false);
  }, [setShowModal, clearSelection]);

  const handleRefresh = useCallback(() => {
    dispatch({
      type: 'refreshTable',
    });
  }, [dispatch]);

  const noValues = useMemo(
    () =>
      !picRelation &&
      !holderRelation &&
      !articleCode &&
      isEmpty(lifeCycleStatus) &&
      // Text the checkbox and not the value because we to be able to update the value to empty
      !showMBOM &&
      !showMechanicalConfiguration &&
      !note,
    [
      picRelation,
      holderRelation,
      articleCode,
      lifeCycleStatus,
      showMBOM,
      showMechanicalConfiguration,
      note,
    ]
  );

  const handleValidation = useCallback(() => {
    if (noValues) {
      addError('Please select at least one valid new value');

      return;
    }

    if (isEmpty(ids)) {
      addError('Please select at least one product in table');

      return;
    }

    ids.forEach(async (product: { id: string; thingName: string }) => {
      // update Partner In Charge (PIC) of product
      if (showPIC && picRelation) {
        const error = await createRelation(
          { ...picRelation, boxId: product.id },
          product.id
        );
        if (error) {
          return;
        }
      }

      // update holder of product
      if (showHolder && holderRelation) {
        const error = await createRelation(
          { ...holderRelation, boxId: product.id },
          product.id
        );
        if (error) {
          return;
        }
      }

      // update product type alias article
      if (showArticle && articleCode) {
        await editProductType(product.thingName, {
          articleCode,
        });
      }

      // update lifeCycle
      if (showLifeCycle && lifeCycleStatus?.status) {
        await addLifecycleStatus(product.thingName, {
          status: lifeCycleStatus.status,
          sub_status: lifeCycleStatus.sub_status!,
        });
      }

      // create note
      if (showNote && !isEmpty(note)) {
        await createNote({
          productId: product.id,
          note,
        });
      }

      const productData = {};
      // update mbom version
      if (showMBOM) {
        productData['productMBOMReference'] = mbomVersion;
      }
      // update mechanical configuration
      if (showMechanicalConfiguration) {
        productData['mechanicalConfiguration'] = mechanicalConfiguration;
      }

      if (!isEmpty(productData)) {
        await updateProduct({
          lpUi: product.thingName,
          values: productData,
        });
      }
    });

    setTimeout(() => {
      clearSelection();
      setShowModal(false);
      handleRefresh();
      setIsScrollTableTop(true);
    }, 500);

    addSuccessMsg(
      { msg: 'Relations updated successfully', type: MessageType.Success },
      'root',
      {}
    );
  }, [
    ids,
    picRelation,
    holderRelation,
    articleCode,
    createRelation,
    addSuccessMsg,
    setShowModal,
    addError,
    editProductType,
    handleRefresh,
    addLifecycleStatus,
    lifeCycleStatus,
    clearSelection,
    setIsScrollTableTop,
    updateProduct,
    mbomVersion,
    mechanicalConfiguration,
    noValues,
    showArticle,
    showHolder,
    showPIC,
    showLifeCycle,
    showMBOM,
    showMechanicalConfiguration,
    note,
    showNote,
    createNote,
  ]);

  const isInvalid = useMemo(() => noValues, [noValues]);

  const modalFooterButtons = CancelSubmitFooterModal(
    isInvalid,
    handleValidation,
    handleOnCancel,
    false
  );

  return (
    <CustomModal
      title={
        <Stack direction="row" alignItems="center">
          Please select the fields to update&nbsp;
          <Text variant="titleS" color="primary.main">
            ({size(ids)} item{size(ids) > 1 ? 's' : ''} selected)
          </Text>
        </Stack>
      }
      open={showModal}
      onCancel={handleOnCancel}
      onOk={handleValidation}
      footer={modalFooterButtons}
      width="80%"
      destroyOnClose
    >
      <HStack alignItems="flex-start">
        <VStack align="flex-start">
          <Checkbox onChange={onPicCheckboxChange}>Partner in charge</Checkbox>
          {showPIC && (
            <Wrapper>
              <SearchPartner
                searchMultiple
                partnerId={partnerId}
                setPartnerId={onPicSelected}
              />
            </Wrapper>
          )}
        </VStack>
        <VStack align="flex-start">
          <Checkbox onChange={() => setShowHolder(!showHolder)}>
            Holder
          </Checkbox>
          {showHolder && (
            <Wrapper>
              <SearchAccount
                accountId={accountId}
                setAccountId={(id) => handleAccountFound(id, 'LP_ACCOUNT')}
              />
              Or LP Shop
              <SearchShop
                shopId={shopId}
                setShopId={(id) => handleAccountFound(id, 'LP_SHOP')}
              />
            </Wrapper>
          )}
        </VStack>
        <VStack align="flex-start">
          <Checkbox
            data-testid="checkbox-article-code"
            onChange={() => setShowArticle(!showArticle)}
          >
            Article
          </Checkbox>
          {showArticle && (
            <Wrapper>
              <ProductTypeForm
                setProductTypeCode={(code) => handleArticleSelected(code)}
              />
            </Wrapper>
          )}
        </VStack>
        <VStack align="flex-start">
          <Checkbox
            data-testid="checkbox-lifecycle"
            onChange={() => setShowLifeCycle(!showLifeCycle)}
          >
            LifeCycle
          </Checkbox>
          {showLifeCycle && (
            <Wrapper>
              <LifeCycleTypeForm
                setLifeCycleTypeCode={(status, sub_status) =>
                  setLifeCycleStatus({
                    status,
                    sub_status,
                  })
                }
                lastState={lifeCycleStatus}
              />
            </Wrapper>
          )}
        </VStack>
      </HStack>
      <HStack marginTop="1rem" alignItems="flex-start">
        <VStack align="flex-start">
          <Checkbox
            data-testid="checkbox-mbom-version"
            onChange={() => setShowMBOM(!showMBOM)}
          >
            MBOM Version
          </Checkbox>
          {showMBOM && (
            <Wrapper>
              <TextField
                data-testid="mbomVersion"
                label="MBOM Version"
                value={mbomVersion}
                onChange={(e) => setMbomVersion(e.target.value)}
              />
            </Wrapper>
          )}
        </VStack>
        <VStack align="flex-start">
          <Checkbox
            data-testid="checkbox-mechanical-configuration"
            onChange={() =>
              setShowMechanicalConfiguration(!showMechanicalConfiguration)
            }
          >
            Mechanical configuration
          </Checkbox>
          {showMechanicalConfiguration && (
            <Wrapper>
              <TextField
                data-testid="mechanicalConfiguration"
                label="Mechanical configuration"
                value={mechanicalConfiguration}
                onChange={(e) => setMechanicalConfiguration(e.target.value)}
              />
            </Wrapper>
          )}
        </VStack>
        <VStack align="flex-start">
          <Checkbox
            data-testid="checkbox-new-note"
            onChange={() => setShowNote(!showNote)}
          >
            New note
          </Checkbox>
          {showNote && (
            <Wrapper>
              <TextField
                multiline
                data-testid="newNote"
                rows={4}
                label="Note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Wrapper>
          )}
        </VStack>
      </HStack>
    </CustomModal>
  );
};
export default EditProductsModal;
