import useShipmentsStore from './useShipmentsStore';
import shipmentsPostProcessor from '../helpers/shipmentsPostProcessor';
import { validationErrorParser } from '@/helpers/beErrorMsgParser';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';

const parsingOptions = {
  errorParser: validationErrorParser,
  trackError: false,
};

const useGetShipments = () => {
  const dispatch = useShipmentsStore((state) => state.dispatch);
  const order = useShipmentsStore((state) => state.order);
  const sortBy = useShipmentsStore((state) => state.sortBy);
  const offset = useShipmentsStore((state) => state.offset);
  const pageSize = useShipmentsStore((state) => state.pageSize);
  const contractState = useShipmentsStore((state) => state.contractState);
  const rts = useShipmentsStore((state) => state.rts);
  const searchTerms = useShipmentsStore((state) => state.searchTerms);
  const isArchived = useShipmentsStore((state) => state.isArchived);
  const getAll = useShipmentsStore((state) => state.getAll);

  usePaginatedTableData({
    params: {
      path: '/shipments',
      method: 'get',
      reducer: shipmentsPostProcessor,
    },
    dispatch,
    queryParams: {
      pageSize,
      offset,
      sortBy,
      order,
      getAll,
      isArchived: isArchived || '',
      contractState: contractState || '',
      rts: rts || '',
      ...searchTerms,
    },
    queryParamsOptions: { skipEmptyString: true },
    service: 'shipment',
    errorParsingOptions: parsingOptions,
  });
};

export default useGetShipments;
