import { useMutation } from '@tanstack/react-query';

import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { MutationConfig } from '@/lib/react-query';

export const updateStatusRTS =
  ({ makeRequest }: { makeRequest: any }) =>
  async (values: { shipmentId: 'string'; status: boolean }): Promise<any> => {
    const { error, data } = await makeRequest({
      path: '/rts_requests/status',
      method: 'post',
      body: values,
    });

    if (!data || error) {
      return { success: false, error: error };
    }

    return { success: true };
  };

type useUpdateStatusRTSOptions = {
  config?: MutationConfig<ReturnType<typeof updateStatusRTS>>;
};

export const useUpdateStatusRTS = ({
  config,
}: useUpdateStatusRTSOptions = {}) => {
  const [, makeRequest] = useImperativeRequestWrapper('deviceV3');

  return useMutation({
    ...config,
    mutationFn: updateStatusRTS({ makeRequest }),
  });
};
