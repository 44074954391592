import React, { useCallback, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';

import useContributionStore from '../../hooks/saContributions/useContributionStore';
import CustomModal from '@/components/CustomModal';
import InputIdForm from '@/components/InputIdForm';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const CancelContribution = ({ getContribution }) => {
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);
  const contributionLoading = useContributionStore((state) => state.loading);
  const contribution = useContributionStore((state) => state.contribution);
  const setError = useContributionStore((state) => state.setError);
  const [showModal, setShowModal] = useState(false);

  const [inputtedId, setInputtedId] = useState({
    data: '',
    touched: false,
  });

  const { id, versionHash, state } = contribution;

  const hasError = inputtedId.data.trim() !== id;

  const [{ loading: requestLoading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');

  const handleCancel = useCallback(async () => {
    const result = await makeRequest({
      path: `/admin/contributions/cancel`,
      method: 'POST',
      body: {
        id: id,
        cancelledAt: Math.floor(Date.now() / 1000),
        versionHash: versionHash,
      },
    });
    if (!!result.error) {
      addError(result.error);
      setError(result.error);

      return;
    }
    await getContribution();
    addSuccessMsg(
      {
        msg: 'Contribution was successfully cancelled',
        type: MessageType.Success,
      },
      'root',
      {}
    );

    setShowModal(false);
  }, [
    id,
    versionHash,
    addError,
    addSuccessMsg,
    makeRequest,
    setError,
    getContribution,
  ]);

  const afterClose = useCallback(() => {
    setInputtedId({ data: '', touched: false });
  }, [setInputtedId]);

  return (
    <>
      <ScopedCta
        component={PillButton}
        danger
        icon={<DeleteOutlined />}
        loading={requestLoading}
        disabled={state === 'cancelled' || contributionLoading}
        onClick={() => setShowModal(true)}
        requiredScopes={[scopes.MANAGE_SHARING_ANGELS]}
      >
        Cancel Contribution
      </ScopedCta>
      <CustomModal
        open={showModal}
        onCancel={() => setShowModal(false)}
        title="You are about to cancel this contribution"
        okText="Cancel Contribution"
        cancelText="Close"
        okButtonProps={{
          danger: true,
          disabled: hasError,
          loading: requestLoading,
        }}
        width="30%"
        onOk={handleCancel}
        afterClose={afterClose}
        destroyOnClose
      >
        <InputIdForm
          placeholder="Contribution-ID"
          text="To cancel this contribution, please enter the contribution's ID:"
          hasError={hasError}
          inputtedId={inputtedId}
          setId={setInputtedId}
          inputName="cancel-modal"
        />
      </CustomModal>
    </>
  );
};

export default CancelContribution;
