import React, { useState, useCallback, useRef } from 'react';

import { Select, Flex } from 'antd';

import { useLifecycleStatuses } from '../api/lifecyle/getLifecycle';

const { Option } = Select;

export const LifeCycleTypeForm = ({
  setLifeCycleTypeCode,
}: {
  setLifeCycleTypeCode: (codeStatus: string, codeSubStatus: string) => void;
  lastState?: any;
}) => {
  const { data: lifecycleStatuses } = useLifecycleStatuses();

  const [statutSelected, setStatutSelected] = useState<string | undefined>(
    undefined
  );
  const [subStatutSelected, setSubStatutSelected] = useState('');
  const [subStatusList, setSubStatusList] = useState<string[]>([]);

  const statutSelectRef = useRef<any>(null);

  const clear = useCallback(() => {
    setSubStatusList([]);
    setSubStatutSelected(() => '');
    setLifeCycleTypeCode('', '');
  }, [setLifeCycleTypeCode]);

  const handleSelectStatus = useCallback(
    (val: string) => {
      clear();

      const selectedStatus = lifecycleStatuses?.statuses?.find(
        (status) => status.status === val
      );

      if (selectedStatus && selectedStatus.sub_statuses?.length) {
        setSubStatusList(selectedStatus.sub_statuses);
      } else {
        setSubStatusList([]);
      }

      setStatutSelected(val);
      setLifeCycleTypeCode(val, '');
    },
    [clear, lifecycleStatuses, setLifeCycleTypeCode]
  );

  const handleSelectSubStatus = useCallback(
    (val: string) => {
      setSubStatutSelected(val);
      if (statutSelected) setLifeCycleTypeCode(statutSelected, val || '');
    },
    [setLifeCycleTypeCode, statutSelected, setSubStatutSelected]
  );

  return (
    <Flex gap="1rem">
      <Select
        data-testid="lifecyle-select-status"
        onChange={handleSelectStatus}
        placeholder="Select a status"
        ref={statutSelectRef}
        value={statutSelected}
        virtual={false}
        style={{
          width: 200,
        }}
      >
        {lifecycleStatuses?.statuses?.map((status) => (
          <Option
            key={status.status}
            value={status.status}
            data-testid={`lifecycle-status-${status.status}`}
          >
            {status.status}
          </Option>
        ))}
      </Select>

      <Select
        allowClear
        data-testid="lifecyle-select-substatus"
        onChange={handleSelectSubStatus}
        placeholder="Select a sub status"
        value={subStatutSelected || undefined}
        style={{
          width: 200,
        }}
      >
        <Option value={undefined}>None</Option>
        {subStatusList.map((substatus) => (
          <Option
            key={substatus}
            value={substatus}
            data-testid={`lifecycle-substatus-${substatus}`}
          >
            {substatus}
          </Option>
        ))}
      </Select>
    </Flex>
  );
};

export default LifeCycleTypeForm;
