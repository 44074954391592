import React from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Box, Grid, Switch, Text } from '@lp/ds-next';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import { Tooltip } from 'antd';

import { IPartnerV3, IPartnerV3Update } from '../../../models/partnerV3';

export const Toggles = ({
  partner,
  updatePartner,
}: {
  partner: IPartnerV3;
  updatePartner: (data: IPartnerV3Update) => Promise<{ success: boolean }>;
}) => {
  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await updatePartner({
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Grid item mobile={12} tablet={4}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">
          <Text variant="titleL">Miscellaneous Controls</Text>
        </FormLabel>
        <FormGroup sx={{ mt: '1rem', gap: '0.5rem' }}>
          <Box>
            <Switch
              label="Code on the BOX"
              checked={partner.code_opening_enabled}
              onChange={onChange}
              name="code_opening_enabled"
            />
          </Box>

          <Box>
            <Switch
              label="Delivery key via SMS"
              checked={partner.sms_enabled}
              onChange={onChange}
              name="sms_enabled"
            />
          </Box>

          <Box>
            <Switch
              label="Remote control"
              checked={partner.remote_control_enabled}
              onChange={onChange}
              name="remote_control_enabled"
            />
          </Box>

          <Box>
            <Switch
              label="Update firmware"
              checked={partner.fw_update_enabled}
              onChange={onChange}
              name="fw_update_enabled"
            />
          </Box>

          <Box>
            <Switch
              label="Flight mode"
              checked={partner.flight_mode_enabled}
              onChange={onChange}
              name="flight_mode_enabled"
            />
          </Box>
          <Box display="flex" flexDirection="row" gap={1}>
            <Switch
              label="Service type"
              checked={partner.service_type_enabled}
              onChange={onChange}
              name="service_type_enabled"
            />
            <Tooltip
              title={
                <Box>
                  {partner.service_types?.map((serviceType) => (
                    <Text key={serviceType.id}>
                      {serviceType.id} - {serviceType.name}
                    </Text>
                  ))}
                </Box>
              }
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Box>
        </FormGroup>
      </FormControl>
    </Grid>
  );
};
