import { useEffect, useState } from 'react';

import axios, { AxiosRequestConfig } from 'axios';

import { TBoxesSortBy } from '@/features/boxes/hooks/useBoxesStore';

type IBody = {
  pageSize: number;
  offset: number;
  order?: 'ASC' | 'DESC';
  sortBy?: TBoxesSortBy;
  searchTerms?: {
    thingName: string;
    boxId: string;
    bleMacAddress: string;
    partnerId: string;
    partnerIds: string[];
    holderId: string;
    notesSearchTerm: string;
  };
  productLifecycles?: {
    status: string;
    subStatuses?: string[];
  }[];
  articleCode?: String[];
};
export interface IUseRemoteData extends AxiosRequestConfig {
  path: string;
  method: 'get' | 'post';
  body?: { [key: string]: string } | IBody;
  schema?: any | null;
  reducer?: any | null;
  reducerExtra?: any | null;
}

interface IUseRemoteDataExt extends IUseRemoteData {
  tokenAndApiUrl: { apiUrl: string; token: string };
  reducerExtra?: any | null;
}

const useRemoteData = ({
  tokenAndApiUrl,
  path = '/',
  method = 'get',
  body,
  responseType = 'json',
  schema = null,
  reducer = null,
  reducerExtra = null,
}: IUseRemoteDataExt) => {
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const { apiUrl, token } = tokenAndApiUrl;
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        await axios({
          url: `${apiUrl}${path}`,
          method,
          data: body,
          responseType,
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((response) => {
            setLoading(false);
            if (!schema) {
              return response.data;
            }
            try {
              return schema.validate(response.data);
            } catch (err) {
              setError(err);
            }
          })
          .then((validated) => {
            if (!reducer) {
              return validated;
            }
            try {
              if (!reducerExtra) {
                return reducer(validated);
              }

              return reducer(validated, reducerExtra);
            } catch (err) {
              setError(err);
            }
          })
          .then((finalData) => {
            setData(finalData);
          });

        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    if (token) {
      getData();
    }
  }, [
    token,
    body,
    method,
    path,
    reducer,
    schema,
    responseType,
    apiUrl,
    reducerExtra,
  ]);

  return { error, loading, data };
};

export default useRemoteData;
