import React, { useCallback, useEffect } from 'react';

import { Pagination } from 'antd';

import BoxNote from './BoxNote';
import boxNotesReducer, { TBoxNotesActions, TBoxNotesState } from './reducer';
import {
  INITIAL_ModifiedBoxNote,
  TModifiedBoxNote,
} from '../../models/boxNote';
import { getCurrentPage, getOffset } from '@/helpers/antdTable';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';
import useServerSideTable, {
  INITIAL_Pagination,
  ServerTablePagination,
} from '@/hooks/useServerSideTable';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const INIT_State: TBoxNotesState = {
  rowData: [INITIAL_ModifiedBoxNote],
  pagination: {
    ...INITIAL_Pagination,
    total: 0,
    pageSize: 5,
    offset: 0,
  },
};

const refetch = async (
  makeRequest,
  pagination: ServerTablePagination,
  boxId: string
) => {
  const queryString = new URLSearchParams({
    offset: `${pagination.offset}`,
    pageSize: `${pagination.pageSize}`,
    sortBy: 'createdAt',
    order: 'DESC',
  });

  return await makeRequest({
    path: `/boxes/${boxId}/notes?${queryString}`,
    method: 'get',
  });
};

const BoxNotes = ({ boxId }: { boxId: string }) => {
  const [{ rowData, pagination }, dispatch] = useServerSideTable<
    TModifiedBoxNote[],
    TBoxNotesState,
    TBoxNotesActions
  >(boxNotesReducer, INIT_State);

  const { data, error } = usePaginatedTableData({
    params: { path: `/boxes/${boxId}/notes`, method: 'get' },
    queryParams: {
      pageSize: pagination.pageSize,
      sortBy: 'createdAt',
      order: 'DESC',
      offset: pagination.offset,
    },
    service: 'device',
  });

  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const [, makeRequest] = useImperativeRequestWrapper('device');

  const submitNote = useCallback(
    async (note: Partial<TModifiedBoxNote>) => {
      const { error } = await makeRequest({
        path: `/boxes/${boxId}/notes${note?.id ? `/${note.id}` : ''}`,
        method: note.id === '' ? 'post' : 'put',
        body: { note: note?.note },
      });
      if (error) {
        addError('Could not submit your note');

        return;
      }
      addSuccessMsg(
        { msg: 'Successfully saved your note', type: MessageType.Success },
        'root',
        {}
      );
      dispatch({ type: 'updateSingleNote', args: { ...note, isDirty: false } });
      const { data } = await refetch(makeRequest, pagination, boxId);
      dispatch({ type: 'updateRowDataFromResponse', args: data });
    },
    [dispatch, makeRequest, addError, addSuccessMsg, boxId, pagination]
  );

  const deleteNote = useCallback(
    async (note: Partial<TModifiedBoxNote>) => {
      if (note?.id === '') {
        addError('You can not delete an empty note');

        return;
      }
      const { error } = await makeRequest({
        path: `/boxes/${boxId}/notes/${note?.id}`,
        method: 'delete',
      });
      if (error) {
        addError('Could not delete note');

        return;
      }
      dispatch({ type: 'deleteNote', args: note });
      addSuccessMsg(
        { msg: 'Successfully deleted your note', type: MessageType.Success },
        'root',
        {}
      );
      const { data } = await refetch(makeRequest, pagination, boxId);
      dispatch({ type: 'updateRowDataFromResponse', args: data });
    },
    [dispatch, makeRequest, addSuccessMsg, addError, boxId, pagination]
  );

  const handlePagination = useCallback(
    (page, pageSize) => {
      dispatch({
        type: 'updatePagination',
        args: { offset: getOffset(pageSize, page), current: page },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    if (error) {
      addError('Could not fetch notes');

      return;
    }
    dispatch({ type: 'updateRowDataFromResponse', args: data });
  }, [dispatch, data, error, addError]);

  return (
    <div>
      {rowData.map((note) => (
        <BoxNote
          key={note.id}
          dispatch={dispatch}
          note={note}
          submitNote={submitNote}
          deleteNote={deleteNote}
        />
      ))}
      <Pagination
        hideOnSinglePage={true}
        showQuickJumper={false}
        simple
        onChange={handlePagination}
        total={pagination.total}
        pageSize={pagination.pageSize}
        current={getCurrentPage(pagination.pageSize, pagination.offset)}
      />
    </div>
  );
};

export default BoxNotes;
