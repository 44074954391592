import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';

import { Stack, Text } from '@lp/ds-next';
import forEach from 'lodash/forEach';
import styled from 'styled-components';

import CountrySelector from './forms/CountrySelector';
import SearchField from './forms/SearchField';
import PartnerAutocomplete from './ParnterAutocomplete';
import SearchButton from './SearchButton';
import SearchResetButton from './SearchResetButton';
import useShipmentsStore from '@/features/shipments/hooks/useShipmentsStore';

interface IField {
  label?: string;
  placeholder?: string;
  key: string;
}

interface IServerSearch {
  fields: IField[];
  dispatch: any;
  storeSearchTerms: {
    [key: string]: any;
  };
  label?: string;
  setSelectedRowKeys?: (keys: any[]) => void;
}

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
`;

const SearchWrapper = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

const ButtonWrapper = styled.div`
  margin-right: 0.5rem;
`;

const trimSearchTerms = (searchTerms: Record<string, any>) => {
  const result: Record<string, any> = {};
  forEach(searchTerms, (value, key) => {
    result[key] = !Array.isArray(value) ? value.trim() : value;
  });

  return result;
};

const getURLSearchParams = (): Record<string, any> => {
  if (typeof window === 'undefined') return {};

  const searchParams = new URLSearchParams(window.location.search);
  const params: Record<string, any> = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  return params;
};

const updateURLParams = (params: Record<string, string>) => {
  if (typeof window === 'undefined') return;

  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value && value !== '') {
      searchParams.set(key, value);
    }
  });

  const newSearch = searchParams.toString();
  const newUrl = `${window.location.pathname}${newSearch ? `?${newSearch}` : ''}`;

  window.history.replaceState({}, '', newUrl);
};

const ServerSearch = ({
  fields,
  dispatch,
  storeSearchTerms,
  label = 'Search by:',
  setSelectedRowKeys = undefined,
}: IServerSearch) => {
  const [initialParams] = useState(getURLSearchParams);
  const partnerAutocomplete = useShipmentsStore(
    (state) => state.partnerAutocomplete
  );

  const [searchTerms, setSearchTerms] = useState(() => {
    const terms: Record<string, any> = {};
    fields.forEach((field) => {
      if (field.key !== 'partnerIds' && field.key !== 'partnerId') {
        if (initialParams[field.key]) {
          terms[field.key] = initialParams[field.key];
        } else if (storeSearchTerms[field.key]) {
          terms[field.key] = storeSearchTerms[field.key];
        } else {
          if (storeSearchTerms['boxId']) {
            terms['id'] = storeSearchTerms['boxId'];
          }
          terms[field.key] = '';
        }
      }
    });

    return terms;
  });

  const [partnerIdsSelected, setPartnerIdsSelected] = useState<any>(() => {
    try {
      if (initialParams.partners) {
        return JSON.parse(initialParams.partners);
      }
    } catch (e) {
      console.warn('Error while reading partners from URL', e);
    }

    return Array.isArray(partnerAutocomplete)
      ? partnerAutocomplete.map((partner: { name: string; id: string }) => ({
          label: partner.name,
          value: partner.id,
        }))
      : [];
  });

  const [isInitialSearch, setIsInitialSearch] = useState<boolean>(true);
  const isHandlingPopstate = useRef(false);

  const partnerIDsFieldExists = useMemo(
    () => fields.some((field) => field.key === 'partnerIds'),
    [fields]
  );

  const partnerIDFieldExists = useMemo(
    () => fields.some((field) => field.key === 'partnerId'),
    [fields]
  );

  const prepareSearchCriteria = useCallback(
    (terms: Record<string, any>, partners: any[]) => ({
      ...terms,
      ...(partnerIDsFieldExists && {
        partnerIds: partners.map((p) => p.value),
      }),
      ...(partnerIDFieldExists && { partnerId: partners.map((p) => p.value) }),
    }),
    [partnerIDsFieldExists, partnerIDFieldExists]
  );

  useEffect(() => {
    if (isInitialSearch && Object.keys(initialParams).length > 0) {
      const mergeCriterias = prepareSearchCriteria(
        searchTerms,
        partnerIdsSelected
      );
      dispatch({ type: 'updateSearchTerms', args: mergeCriterias });
      setIsInitialSearch(false); // Marquer la recherche initiale comme terminée
    }
  }, [
    initialParams,
    searchTerms,
    partnerIdsSelected,
    prepareSearchCriteria,
    dispatch,
    isInitialSearch,
  ]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setSearchTerms((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    []
  );

  const handleCountryCodeChange = useCallback((option: string) => {
    setSearchTerms((prev) => ({
      ...prev,
      countryCode: option,
    }));
  }, []);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (isHandlingPopstate.current) {
      return;
    }

    const trimmedTerms = trimSearchTerms(searchTerms);

    const urlParams = {
      ...trimmedTerms,
      ...(partnerIdsSelected.length > 0 && {
        partners: JSON.stringify(partnerIdsSelected),
      }),
    };

    updateURLParams(urlParams);

    const mergeCriterias = prepareSearchCriteria(
      trimmedTerms,
      partnerIdsSelected
    );

    dispatch({ type: 'updateSearchTerms', args: mergeCriterias });

    setIsInitialSearch(false); // Indiquer que la recherche est manuelle

    if (setSelectedRowKeys) {
      setSelectedRowKeys([]);
    }
  };

  const handleReset = useCallback(() => {
    if (isHandlingPopstate.current) {
      return;
    }

    setSearchTerms(
      fields.reduce((acc, field) => {
        if (field.key !== 'partnerIds' && field.key !== 'partnerId') {
          acc[field.key] = '';
        }

        return acc;
      }, {})
    );

    setPartnerIdsSelected([]);

    updateURLParams({});

    if (setSelectedRowKeys) {
      setSelectedRowKeys([]);
    }

    const clearedState = fields.reduce((acc, field) => {
      acc[field.key] = '';

      return acc;
    }, {});

    dispatch({ type: 'updateSearchTerms', args: clearedState });
  }, [fields, setSelectedRowKeys, dispatch]);

  return (
    <Stack component="form" onSubmit={handleSubmit} rowGap="0.75rem">
      <Text variant="titleM">{label}</Text>
      <StyledRow>
        {fields.map((field: IField) => {
          if (field.key === 'countryCode') {
            return (
              <SearchWrapper key={field.key}>
                <CountrySelector
                  name="country"
                  onChange={handleCountryCodeChange}
                  countryCodeValue={searchTerms[field.key]}
                  placeholder="Country"
                  width="100%"
                />
              </SearchWrapper>
            );
          }
          if (field.key === 'partnerIds' || field.key === 'partnerId') {
            return (
              <SearchWrapper key={field.key}>
                <PartnerAutocomplete
                  placeholder={field.placeholder}
                  style={{ width: '250px', marginRight: '15px' }}
                  updateSearch={setPartnerIdsSelected}
                  isMultiple={field.key === 'partnerIds'}
                  initialValue={partnerIdsSelected}
                  name={field.key}
                />
              </SearchWrapper>
            );
          }

          return (
            <SearchWrapper key={field.key}>
              <SearchField
                value={searchTerms[field.key] || ''}
                name={field.key}
                placeholder={field.placeholder}
                label={field.label}
                handleChange={handleChange}
                width="100%"
              />
            </SearchWrapper>
          );
        })}
        <ButtonWrapper>
          <SearchButton />
        </ButtonWrapper>
        <ButtonWrapper>
          <SearchResetButton handleReset={handleReset} />
        </ButtonWrapper>
      </StyledRow>
    </Stack>
  );
};

export default ServerSearch;
