import { useQuery } from '@tanstack/react-query';

import { LifecycleStatuses } from '../../models/boxLifecycle';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getLifecycleStatuses = async ({
  makeRequest,
}: {
  makeRequest: any;
}): Promise<LifecycleStatuses | null> => {
  const { data, error } = await makeRequest({
    path: `/products/lifecycle-status/statuses`,
  });

  if (error) {
    throw new Error(error);
  }

  return data;
};

type QueryFnTypeStatuses = typeof getLifecycleStatuses;

type UseLifeCyleStatusesOptions = {
  config?: QueryConfig<QueryFnTypeStatuses>;
};

export const useLifecycleStatuses = ({
  config,
}: UseLifeCyleStatusesOptions = {}) => {
  const [, makeRequest] = useImperativeRequestWrapper('deviceV3');

  return useQuery<ExtractFnReturnType<QueryFnTypeStatuses>>({
    ...config,
    queryKey: ['lifecycleStatus'],
    queryFn: () => getLifecycleStatuses({ makeRequest }),
  });
};
