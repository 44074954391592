import React from 'react';

import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';
import styled from 'styled-components';

import LPAccountUsersTable from './LPAccountUsersTable';
import useGetAccounts from '../hooks/useGetAccounts';
import useGetIcons from '../hooks/useGetIcons';
import useLPUsersStore from '../hooks/useLPUsersStore';
import MoneyBagImage from '@/assets/images/money-bag.png';
import BuyNRentIcon from '@/components/icons/BuyNRentIcon';
import ContributionIcon from '@/components/icons/ContributionIcon';
import { appRoutes } from '@/configs/appRoutes';
import { getDefaultSortOrder } from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';

const IMG = styled.img`
  height: 20px;
  width: auto;
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const WrapperAccountsTable = () => {
  const defaultSort = useLPUsersStore((state) => state.defaultSort);
  const filters = useLPUsersStore((state) => state.filters);
  useGetAccounts();

  const [buyNRenters, contributors, bothUsers] = useGetIcons();

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: ['accountId', 'email', 'firstName', 'lastName', 'invest'],
    columnsSpecialProps: {
      firstName: {
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(defaultSort, 'firstName'),
      },
      lastName: {
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(defaultSort, 'lastName'),
      },
      email: { width: '20rem' },
      accountVerified: {
        filteredValue: get(filters, 'accountVerified'),
      },
      invest: {
        filters: [
          { text: 'SHOW CONTRIBUTION', value: 'contributors' },
          { text: 'SHOW B’N’R', value: 'buyNRenters' },
          { text: 'SHOW EMPTY', value: 'empty' },
        ],
        filterMultiple: false,
        filteredValue: get(filters, 'invest'),
        width: '3rem',
        title: (
          <ImageWrapper>
            <IMG src={MoneyBagImage} />
          </ImageWrapper>
        ),
        render: (_text, record) => {
          if (contributors.find((user) => user.id === record.id)) {
            return (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ContributionIcon />
              </div>
            );
          } else if (buyNRenters.find((user) => user.id === record.id)) {
            return (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <BuyNRentIcon />
              </div>
            );
          } else if (bothUsers.find((user) => user.id === record.id)) {
            return (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <BuyNRentIcon />
                <ContributionIcon />
              </div>
            );
          }
        },
      },
    },
    addDefaultColumns: true,
    defaultSort: defaultSort,
    eyeLinkProps: {
      to: appRoutes.LPACCOUNT,
      filters: [
        { text: 'Show checked', value: true },
        { text: 'Show empty', value: false },
      ],
      filterMultiple: false,
      filteredValue: get(filters, 'accountVerified'),
      key: 'accountVerified',
    },
  });

  return <LPAccountUsersTable withSearch={true} columns={columns} />;
};
export default WrapperAccountsTable;
