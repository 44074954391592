import * as React from 'react';

import SVG from './SVG';

export const IsolatedIcon = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    id="Calque_2"
    viewBox="0 0 60.65 58.93"
    {...props}
  >
    <defs>
      <style>
        {
          '.st0{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}'
        }
      </style>
    </defs>
    <circle cx={43.13} cy={41.42} r={16.52} className="st0" />
    <path
      d="M23.18 7.69c4.26-1.1 8.69-.88 12.81.64l2.08-5.63a27.31 27.31 0 0 0-16.39-.82C14.57 3.72 8.6 8.22 4.87 14.55 1.14 20.88.1 28.29 1.94 35.4c.42 1.64 1 3.23 1.71 4.75l-2.64 1.99 11.56 4.93-1.51-12.47-2.47 1.85c-.33-.83-.61-1.68-.83-2.55-1.44-5.56-.62-11.35 2.29-16.3 2.92-4.95 7.58-8.47 13.15-9.91ZM35.33 33.62l15.6 15.6M50.93 33.62l-15.6 15.6"
      className="st0"
    />
    <path
      d="M37.55 21.33c-2.1-2.36-5.15-3.86-8.56-3.86-6.33 0-11.47 5.13-11.47 11.47 0 3.93 1.98 7.39 4.98 9.45M47.55 18.42c.5.94.93 1.92 1.29 2.94 2.57.73 4.93 1.94 6.99 3.53-.15-1.09-.35-2.18-.63-3.27-.68-2.64-1.76-5.12-3.17-7.43l2.42-2.28L42.42 8.3l2.89 12.23 2.24-2.11ZM23.89 49.48c-1.7-.38-3.36-.95-4.94-1.74l-2.69 5.37c3.75 1.88 7.85 2.85 11.97 2.9a20.969 20.969 0 0 1-4.35-6.53Z"
      className="st0"
    />
  </SVG>
);
