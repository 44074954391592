import React, { useCallback, useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Form, Input, Modal, Select } from 'antd';

import { BoxLifecycleHistory } from './BoxLifecycleHistory';
import { LifeCycleTypeForm } from './LifeCycleTypeForm';
import useBoxesLifecycleStore from '../hooks/useBoxesLifecycleStore';
import useBoxLifecycle from '../hooks/useBoxLifecycle';
import { FLAGGED_REASONS } from '../models/boxLifecycle';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const { Option } = Select;

const INTIAL_LIFECYCLE_STATUS = {
  status: '',
  sub_status: '',
  reason: '',
  details: '',
};

export const BoxLifecycle = ({ boxId }: { boxId: string }) => {
  const { getLifecycleStatuses, addLifecycleStatus, loadingCreate } =
    useBoxLifecycle();

  const { rowData: lifecycleStatuses, loading } = useBoxesLifecycleStore();

  const [lifeCycleStatus, setLifeCycleStatus] = useState<{
    status: string;
    sub_status?: string;
    reason: string;
    details: string;
  }>(INTIAL_LIFECYCLE_STATUS);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Show FLAGGED Complaintary Info Modal when status is FLAGGED
  useEffect(() => {
    if (lifeCycleStatus?.sub_status === 'FLAGGED') {
      showModal();
    }
  }, [lifeCycleStatus?.sub_status]);

  /// Initial Status of the current box
  const [initialStatus, setInitialStatus] = useState<{
    status: string;
    sub_status?: string;
  }>({
    status: '',
    sub_status: '',
  });

  useEffect(() => {
    getLifecycleStatuses(boxId);
  }, [getLifecycleStatuses, boxId]);

  useEffect(() => {
    if (lifecycleStatuses.length) {
      const [latestStatus] = lifecycleStatuses;

      setInitialStatus(latestStatus);
      setLifeCycleStatus((prev) => ({
        ...prev,
        status: latestStatus.status,
      }));
    }
  }, [lifecycleStatuses, setInitialStatus]);

  /// FLAGGED Modal

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    // Clear `FLAGGED` sub_status
    setLifeCycleStatus((prev) => ({
      ...prev,
      sub_status: '',
      reason: '',
      details: '',
    }));

    setIsModalOpen(false);
  };

  /// End FLAGGED Modal

  const updateLifeCycleStatus = useCallback((status, sub_status) => {
    setLifeCycleStatus((prev) => ({ ...prev, status, sub_status }));
  }, []);

  const handleSubmitStatus = async () => {
    if (lifeCycleStatus?.status) {
      const { success } = await addLifecycleStatus(boxId, lifeCycleStatus);
      if (success) await getLifecycleStatuses(boxId);
    }
  };

  const handleSubmitFlaggedStatus = async () => {
    const { success } = await addLifecycleStatus(boxId, lifeCycleStatus);
    if (success) await getLifecycleStatuses(boxId);

    setIsModalOpen(false);
  };

  const statusChangedByUser =
    lifeCycleStatus?.status !== initialStatus?.status ||
    lifeCycleStatus?.sub_status !== initialStatus?.sub_status;

  return (
    <>
      <Card
        loading={loading}
        title={
          <Flex gap="1rem">
            <LifeCycleTypeForm
              setLifeCycleTypeCode={updateLifeCycleStatus}
              lastState={lifeCycleStatus}
            />
            {lifeCycleStatus.sub_status !== 'FLAGGED' && (
              <ScopedCta
                component={Button}
                type="primary"
                loading={loadingCreate}
                icon={<PlusOutlined />}
                onClick={handleSubmitStatus}
                requiredScopes={[scopes.EDIT_BOX_CONFIG]}
                disabled={
                  loadingCreate ||
                  !lifeCycleStatus?.status ||
                  !statusChangedByUser
                }
                data-testid="btn-add-status"
              >
                Add Status
              </ScopedCta>
            )}
          </Flex>
        }
        style={{ width: '100%', maxWidth: 950, marginBottom: '2rem' }}
      >
        {/* List of lifecycles */}
        <BoxLifecycleHistory lifecycleStatuses={lifecycleStatuses} />
      </Card>
      <Modal
        title="Flag a box"
        open={isModalOpen}
        onOk={loadingCreate ? undefined : handleSubmitFlaggedStatus}
        okButtonProps={{
          loading: loadingCreate,
          disabled: loadingCreate || !lifeCycleStatus?.reason,
          'data-testid': 'modal-submit-button',
        }}
        okText="Add Status"
        onCancel={handleCancel}
      >
        <Form layout="vertical">
          <Form.Item name="reason" rules={[{ required: true }]}>
            <Select
              data-testid="select-reason-flagged"
              allowClear
              value={lifeCycleStatus?.reason}
              placeholder="The reason"
              onChange={(value) => {
                setLifeCycleStatus((prev) => ({
                  ...prev,
                  reason: value,
                }));
              }}
              style={{
                textTransform: 'capitalize',
              }}
            >
              {FLAGGED_REASONS.map((reason) => (
                <Option key={reason} value={reason}>
                  {reason}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Note (optional)">
            <Input.TextArea
              data-testId="details-flagged"
              rows={5}
              name="details"
              value={lifeCycleStatus?.details}
              onChange={(event) => {
                setLifeCycleStatus((prev) => ({
                  ...prev,
                  details: event.target.value,
                }));
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
