import React from 'react';

import { Card, Flex, Tag, Typography } from 'antd';
import styled from 'styled-components';

import CopyToClipboard from '@/components/CopyToClipboard';
import DownArrowIcon from '@/components/icons/DownArrowIcon';
import { formatISODate } from '@/helpers/dates';

const { Text } = Typography;

const SourceWrapper = styled.div`
  color: #808080;
  display: flex;
  justify-content: end;
  margin-right: 25px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding-right: 3rem;
`;

const TitleWrapper = styled.h2`
  font-family: TTNormsPro-bold;
  font-size: 20px;
  display: flex;
  align-items: end;
  height: 100%;
`;

const DownArrowWrapper = styled.div`
  margin-right: 5px;
`;

export const TimeLineAltCard = ({ timeLineEvent }) => (
  <Flex vertical gap="0.5rem" style={{ paddingTop: '1rem' }}>
    <Flex gap="1rem" justify="space-between">
      <Flex gap="1rem">
        <Tag
          bordered={false}
          style={{
            fontWeight: 'bold',
          }}
        >
          {formatISODate(timeLineEvent.created_at, 'dd/MM/yyyy - HH:mm:ss')}
        </Tag>
      </Flex>
      <SourceWrapper>
        {`${
          timeLineEvent.source_type === 'physical_station'
            ? `${timeLineEvent.source_name} / ${timeLineEvent.station_source}`
            : timeLineEvent.station_source
        }`}
      </SourceWrapper>
    </Flex>
    <Flex align="center">
      <DownArrowWrapper>
        <DownArrowIcon />
      </DownArrowWrapper>
      <Card
        style={{
          width: '100%',
          marginRight: '1.5625rem',
          boxShadow: '0 .25rem .25rem 0 rgba(0, 0, 0, 0.25)',
        }}
      >
        <Flex vertical>
          <TitleWrapper data-testId="subStatus-title">
            {timeLineEvent.sub_status}
          </TitleWrapper>
          <Flex gap="1rem" justify="space-between">
            <Flex vertical gap=".5rem">
              <Flex vertical>
                <Text>
                  by <b>{timeLineEvent.created_by.email}</b>
                </Text>
                <Text>{timeLineEvent.created_by.id}</Text>
              </Flex>
              {timeLineEvent.sub_status === 'FLAGGED' && (
                <Flex vertical>
                  <Text data-testId="subStatus-reason">
                    <strong>Reason:</strong> {timeLineEvent.reason || 'N/A'}
                  </Text>
                  <Text data-testId="subStatus-details">
                    <strong>Notes:</strong> {timeLineEvent.details || 'N/A'}
                  </Text>
                </Flex>
              )}
            </Flex>
            <IconWrapper>
              <CopyToClipboard data="test" round={true} />
            </IconWrapper>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  </Flex>
);
