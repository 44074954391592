import React from 'react';

import { Grid, GridItem } from '@chakra-ui/react';
import { Tag } from 'antd';
import styled from 'styled-components';

import { cleanName } from '../helpers/timeLineHelpers';
import CopyToClipboard from '@/components/CopyToClipboard';
import BoxDestroyedIcon from '@/components/icons/BoxDestroyedIcon';
import BoxIcon from '@/components/icons/BoxIcon';
import BoxProIcon from '@/components/icons/BoxProIcon';
import BusinessIcon from '@/components/icons/BusinessIcon';
import EarthIcon from '@/components/icons/EarthIcon';
import { IsolatedIcon } from '@/components/icons/IsolatedIcon';
import TabletIcon from '@/components/icons/TabletIcon';
import { formatISODate } from '@/helpers/dates';

const CardWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #008379;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-right: 25px;
`;

const SourceWrapper = styled.div`
  color: #808080;
  display: flex;
  justify-content: end;
  margin-right: 25px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const TitleWrapper = styled.h2`
  font-family: TTNormsPro-bold;
  font-size: 20px;
  display: flex;
  align-items: end;
  height: 100%;
`;

const TextWrapper = styled.div`
  padding-bottom: 10px;
`;

const HeadWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const IconSelection = ({ eventType }) => {
  switch (eventType) {
    case 'TABLET_IN_STOCK':
      return <TabletIcon width={80} height={60} />;
    case 'TABLET_IN_QUALITY_PRISON':
      return <TabletIcon width={80} height={60} />;
    case 'PRODUCT_IN_QUALITY_PRISON':
      return <BoxIcon />;
    case 'PRODUCT_IN_STOCK':
    case 'PRODUCT_IN_REWORK':
      return <BoxProIcon width={80} height={60} />;
    case 'IN_CIRCULATION':
      return <EarthIcon width={80} height={60} />;
    case 'IN_BUSINESS':
      return <BusinessIcon width={80} height={60} />;
    case 'OUT_OF_BUSINESS':
      return <BusinessIcon width={80} height={60} />;
    case 'OUT_OF_CIRCULATION':
      return <EarthIcon width={80} height={60} />;
    case 'PRODUCT_DESTROYED':
      return <BoxDestroyedIcon width={80} height={60} />;
    case 'TABLET_REFURBISHED':
      return <TabletIcon width={80} height={60} />;
    case 'TABLET_DESTROYED':
      return <BusinessIcon width={80} height={60} />;
    case 'ISOLATED':
      return <IsolatedIcon width={80} height={60} />;
  }
};

export const TimeLineMainCard = ({ timeLineEvent }) => (
  <Grid templateRows="1fr 3fr" h="128px">
    <GridItem>
      <Grid templateColumns="105px repeat(3, 1fr)">
        <GridItem paddingBottom="1rem">
          <Tag
            bordered={false}
            style={{
              fontWeight: 'bold',
            }}
          >
            {formatISODate(timeLineEvent.created_at, 'dd/MM/yyyy - HH:mm:ss')}
          </Tag>
        </GridItem>
        <GridItem colStart={5} colEnd={5} colSpan={2}>
          <SourceWrapper>
            {`${
              timeLineEvent.source_type === 'physical_station'
                ? `${timeLineEvent.source_name} / ${timeLineEvent.station_source}`
                : timeLineEvent.station_source
            }`}
          </SourceWrapper>
        </GridItem>
      </Grid>
    </GridItem>
    <CardWrapper>
      <Grid
        h="100%"
        templateAreas={`'pic title icon'
                          'pic text icon'`}
        gridTemplateRows="1fr 1fr"
        gridTemplateColumns="1fr 3fr 1fr"
      >
        <GridItem gridArea="pic">
          <HeadWrapper>
            <IconSelection eventType={timeLineEvent.status} />
          </HeadWrapper>
        </GridItem>
        <GridItem gridArea="title">
          <TitleWrapper>{cleanName(timeLineEvent.status)}</TitleWrapper>
        </GridItem>
        <GridItem gridArea="text">
          <TextWrapper>
            by <b>{timeLineEvent.created_by.email}</b>
            <div>{timeLineEvent.created_by.id}</div>
          </TextWrapper>
        </GridItem>
        <GridItem gridArea="icon">
          <IconWrapper>
            <CopyToClipboard round data={timeLineEvent.created_by.id} />
          </IconWrapper>
        </GridItem>
      </Grid>
    </CardWrapper>
  </Grid>
);
