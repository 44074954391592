import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { LPAccountsListView } from './views/LPAccountsListView';
import LPAccountUserDetails from './views/LPAccountUserDetails';
import ScopedRoute from '@/components/ScopedRoute';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';
import useAppState from '@/hooks/useAppState';
import AccountsTotalProvider from '@/shared/providers/accountsTotalProvider';

const LPAccountUsers = () => {
  const userScopes = useAppState((state) => state.scopes);

  return (
    <AccountsTotalProvider>
      <Routes>
        <Route
          path={appRoutes.ROOT}
          element={
            <ScopedRoute
              component={<LPAccountsListView />}
              userScopes={userScopes}
              requiredScopes={[scopes.VIEW_LPACCOUNT]}
            />
          }
        />
        <Route
          path="/:id"
          element={
            <ScopedRoute
              component={<LPAccountUserDetails />}
              userScopes={userScopes}
              requiredScopes={[scopes.VIEW_LPACCOUNT]}
            />
          }
        />
      </Routes>
    </AccountsTotalProvider>
  );
};

export default LPAccountUsers;
