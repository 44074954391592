import React, { useCallback, useContext, useEffect, useState } from 'react';

import { ColumnProps } from 'antd/es/table';
import { isArray } from 'lodash';
import get from 'lodash/get';
import { useMatch } from 'react-router-dom';

import EditProductsModal from './MultiEdit/EditProductsModal';
import { useLifecycleStatuses } from '../api/lifecyle/getLifecycle';
import useBoxesStore, { TBoxesSortBy } from '../hooks/useBoxesStore';
import useGetArticleCodes from '../hooks/useGetArticleCodes';
import useGetBoxes from '../hooks/useGetBoxes';
import StyledTable from '@/components/tables/StyledTable';
import TablesTotals from '@/components/TablesTotals';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import { ColumnTitle } from '@/helpers/columnTitles';
import useAntdColumns from '@/hooks/useAntdColumns';
import useAppState from '@/hooks/useAppState';
import useWindowSize from '@/hooks/useWindowSize';
import { ProductTotalContext } from '@/shared/providers/productTotalProvider';

const ProductsTable = ({
  partnerId = '',
  showModal,
  setShowModal,
  selectedRowKeys,
  setSelectedRowKeys,
}: {
  partnerId?: string;
  showModal?: boolean;
  setShowModal?: any;
  selectedRowKeys?: React.Key[];
  setSelectedRowKeys?: any;
}) => {
  useGetBoxes(partnerId);
  const { articles } = useGetArticleCodes();
  const { data: lifeCycleStatuses } = useLifecycleStatuses();
  const matchRoute = useMatch(appRoutes.PRODUCTS.ROOT);

  const [selectedRowIds, setSelectedRowIds] = useState<any>();
  const [isScrollTableTop, setIsScrollTableTop] = useState<boolean>(false);
  const rowData = useBoxesStore((state) => state.rowData);
  const loading = useBoxesStore((state) => state.loading);
  const pageSize = useBoxesStore((state) => state.pageSize);
  const offset = useBoxesStore((state) => state.offset);
  const total = useBoxesStore((state) => state.total);
  const defaultSort = useBoxesStore((state) => state.defaultSort);
  const dispatch = useBoxesStore((state) => state.dispatch);

  const windowSize = useWindowSize();

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  const pTotal = useContext(ProductTotalContext);

  const userScopes = useAppState((state) => state.scopes);

  const onSelectChange = useCallback(
    (newSelectedRowKeys: React.Key[]) => {
      const ids = rowData
        .filter((p) => newSelectedRowKeys.includes(p.id))
        .map((product) => ({ thingName: product.thingName, id: product.id }));

      setSelectedRowIds(ids);
      setSelectedRowKeys(newSelectedRowKeys);
    },
    [setSelectedRowKeys, setSelectedRowIds, rowData]
  );

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as TBoxesSortBy, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });

      const productLifecycles = get(filters, 'productLifecycle', [])?.reduce(
        (accumulator, item) => {
          const [status, subStatus] = item.split('-');
          const existingStatus = accumulator.find((i) => i.status === status);

          if (existingStatus) {
            if (subStatus) {
              existingStatus.subStatus = existingStatus.subStatus || [];
              existingStatus.subStatus.push(subStatus);
            }
          } else {
            accumulator.push(
              subStatus ? { status, subStatuses: [subStatus] } : { status }
            );
          }

          return accumulator;
        },
        []
      );

      dispatch({
        type: 'updateFilters',
        args: {
          productLifecycles,
          productDescription: isArray(get(filters, 'productDescription'))
            ? filters.productDescription
            : null,
        },
      });
    },
    [dispatch]
  );

  const rowSelection = userScopes.includes(scopes.REGISTER_BOX)
    ? {
        selectedRowKeys,
        rowData,
        onChange: onSelectChange,
      }
    : undefined;

  const lifecycleFilters =
    lifeCycleStatuses?.statuses.map(({ status, sub_statuses }) => ({
      text: status,
      value: status,
      children:
        sub_statuses.length > 0
          ? sub_statuses.map((subStatus) => ({
              text: subStatus,
              value: `${status}-${subStatus}`,
            }))
          : undefined,
    })) || [];

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'thingName',
      'productDescription',
      'mechanicalConfiguration',
      'productLifecycle',
      'partnerInChargeId',
      'status',
      'parsedManufacturingDate',
      'bluetoothMacAddress',
      'firmwareVersion',
      'updatedAt',
    ],
    addDefaultColumns: false,
    columnsSpecialProps: {
      productLifecycle: {
        filters: lifecycleFilters,
        filterMode: 'tree',
        filterSearch: true,
        width: '10rem',
        render: (text, record) => {
          const { productLifecycle, productLifecycleSubStatus } = record;

          return (
            <>
              <div>{productLifecycle}</div>
              {productLifecycleSubStatus && (
                <div
                  style={{
                    paddingLeft: '8px',
                    fontSize: '0.8rem',
                    fontStyle: 'italic',
                  }}
                >
                  {productLifecycleSubStatus}
                </div>
              )}
            </>
          );
        },
      },

      reference: {
        width: '10rem',
      },
      partnerInChargeId: {
        title: matchRoute
          ? ColumnTitle['holder']
          : ColumnTitle['partnerInChargeId'],
      },
      productDescription: {
        filters:
          articles &&
          articles.map((article) => ({
            text: article.description,
            value: article.code,
          })),
        filterMultiple: true,
        width: '6rem',
      },
    },
    defaultSort: defaultSort,
    eyeLinkProps: { to: '/products', queryParam: 'thingName' },
  });

  useEffect(() => {
    if (isScrollTableTop) {
      const tableBody = document.querySelector('.ant-table-body');
      tableBody?.scrollTo({ top: 0, behavior: 'smooth' });
      setIsScrollTableTop(false);
    }
  }, [isScrollTableTop]);

  return (
    <>
      <StyledTable
        rowKey="id"
        rowSelection={rowSelection}
        dataSource={rowData}
        columns={columns}
        size="small"
        pagination={pagination}
        loading={loading}
        scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
        onChange={handleTableChange}
        footer={() => (
          <TablesTotals
            total={total}
            gTotal={pTotal}
            selectedItems={selectedRowKeys?.length}
          />
        )}
        data-testid="products-table"
      />
      <EditProductsModal
        ids={selectedRowIds}
        showModal={showModal}
        setShowModal={setShowModal}
        setIsScrollTableTop={setIsScrollTableTop}
      />
    </>
  );
};
export default ProductsTable;
