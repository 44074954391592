export interface boxLifecycle {
  sub_status: any;
  id: string;
  status: string;
  createdBy: {
    id: string;
    email: string;
  };
  createdAt: number;
  parsedCreatedAt: string;
  reason: string;
  details?: string;
}

export const FLAGGED_REASONS = [
  'Damaged',
  'End of contract',
  'Firmware to update',
  'Litigation',
  'Recalled',
  'Security lock',
  'Other',
];

export type TLifecyclestatuses =
  | ''
  | 'DRAFT'
  | 'PURCHASED'
  | 'DEPLOYED'
  | 'ARRIVED'
  | 'ARCHIVED'
  | 'ERROR';

export type LifecycleStatus =
  | 'INIT'
  | 'TABLET_IN_STOCK'
  | 'TABLET_IN_QUALITY_PRISON'
  | 'PRODUCT_IN_STOCK'
  | 'PRODUCT_IN_QUALITY_PRISON'
  | 'PRODUCT_IN_REWORK'
  | 'IN_CIRCULATION'
  | 'IN_BUSINESS'
  | 'OUT_OF_BUSINESS'
  | 'OUT_OF_CIRCULATION'
  | 'PRODUCT_IN_REPAIR'
  | 'PRODUCT_DESTROYED'
  | 'TABLET_REFURBISHED'
  | 'TABLET_DESTROYED'
  | 'LOST_CAUSE'
  | 'OUT_OF_SERVICE'
  | 'KEPT_BY_OWNER'
  | 'PRODUCT_IN_LITIGATION'
  | 'ISOLATED'
  | '';

export type SubStatus =
  | 'TO_ANALYSE'
  | 'TO_REFURBISH'
  | 'TO_REUSE'
  | 'TO_END_SERVICE'
  | 'TO_REWORK'
  | 'TO_DISMANTLE'
  | 'TO_ANALYSE_LVL_1'
  | 'TO_ANALYSE_LVL_2'
  | 'TO_REPAIR'
  | 'TO_UPGRADE'
  | 'DAMAGED'
  | 'RECALLED'
  | 'END_OF_CONTRACT'
  | 'SECURITY_LOCK'
  | 'FLAGGED'
  | 'INTERNAL_USE'
  | 'LITIGATION'
  | 'TO_OWNER_KEEP';

export type LifecycleStatuses = {
  statuses: {
    status: LifecycleStatus;
    sub_statuses: SubStatus[];
  }[];
};

export interface ILifecycleSubStatus {
  allowed_transitions: {
    [key: string]: { sub_statuses: string[] };
  };
}
