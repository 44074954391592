import React, { useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Select, Typography, Row, Col, Input } from 'antd';

import {
  PARTNER_STATUS,
  PARTNER_TYPES,
  PARTNER_PROFILE,
  PARTNER_ERP_SYNCHRONISATION_STATE,
  PARTNER_ACCOUNT_STATUS,
} from '../types/partner';
import { DropdownV2 } from '@/components/forms/DropdownV2/Dropdown';
import { capitalizeFirstLetter, trimSearchTerms } from '@/utils/format';

const { Text } = Typography;
const SEARCH_COL_SIZE = 3;

const partnerTypeOptions = Object.values(PARTNER_TYPES).map((type) => ({
  value: type,
  label: capitalizeFirstLetter(type),
}));

const partnerStatusOptions = Object.values(PARTNER_STATUS).map((status) => ({
  value: status,
  label: capitalizeFirstLetter(status),
}));

const partnerProfileOptions = Object.values(PARTNER_PROFILE).map((profile) => ({
  value: profile === PARTNER_PROFILE.INTERNAL,
  label: capitalizeFirstLetter(profile),
}));

const partnerAccountStatusOptions = Object.values(PARTNER_ACCOUNT_STATUS).map(
  (status) => ({
    value: status === PARTNER_ACCOUNT_STATUS.COMPLETED,
    label: capitalizeFirstLetter(status.replace('_', ' ')),
  })
);

const partnerERPSyncOptions = Object.values(
  PARTNER_ERP_SYNCHRONISATION_STATE
).map((status) => ({
  value: status === PARTNER_ERP_SYNCHRONISATION_STATE.SYNCHRONISED,
  label: capitalizeFirstLetter(status.replace('_', ' ')),
}));

export const LPPartnerFilterForm = ({ dispatch }) => {
  const [searchTerms, setSearchTerms] = useState<{
    [key: string]: string | string[];
  }>({
    businessTypes: [PARTNER_TYPES.PRO, PARTNER_TYPES.TEST],
  });

  const handleChange = (event) => {
    setSearchTerms({
      ...searchTerms,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const trimmedTerms = trimSearchTerms(searchTerms);
    const { businessStatuses, businessTypes, ...rest } = trimmedTerms as any;

    dispatch({
      type: 'updateSearchTerms',
      args: {
        ...rest,
        ...(businessStatuses
          ? { businessStatuses: businessStatuses.join(',') }
          : {}),
        ...(businessTypes ? { businessTypes: businessTypes.join(',') } : {}),
      },
    });
  };

  const handleReset = () => {
    setSearchTerms({});
    dispatch({
      type: 'updateSearchTerms',
      args: {
        businessTypes: [PARTNER_TYPES.PRO, PARTNER_TYPES.TEST].join(','),
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col
          style={{
            marginBottom: '0.75rem',
          }}
        >
          <Text
            style={{
              fontSize: '1rem',
              fontWeight: '500',
            }}
          >
            Search by:
          </Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={8}>
          <Input
            name="name"
            placeholder="Name"
            onChange={handleChange}
            value={searchTerms['name']}
          />
        </Col>
        <Col md={8}>
          <Input
            name="clientId"
            placeholder="Client-ID"
            onChange={handleChange}
            value={searchTerms['clientId']}
          />
        </Col>
        <Col md={4}>
          <Button
            type="primary"
            htmlType="submit"
            shape="round"
            icon={<SearchOutlined />}
            onClick={handleSubmit}
            data-testid="partner-filter-search-button"
          >
            Search
          </Button>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
      >
        <Col md={SEARCH_COL_SIZE}>
          <DropdownV2
            value={searchTerms.businessTypes}
            data-testid="partner-filter-form-type"
            options={partnerTypeOptions}
            style={{ width: '100%' }}
            onChange={(value) => {
              setSearchTerms({ ...searchTerms, businessTypes: value });
            }}
            placeholder="Account type"
          />
        </Col>
        <Col md={SEARCH_COL_SIZE}>
          <Select
            allowClear
            placeholder="Partner profile"
            options={partnerProfileOptions}
            value={searchTerms.isInternal}
            onChange={(value) => {
              setSearchTerms({ ...searchTerms, isInternal: value });
            }}
            style={{ width: '100%' }}
            data-testid="partner-filter-form-profile"
          />
        </Col>
        <Col md={SEARCH_COL_SIZE + 1}>
          <DropdownV2
            data-testid="partner-filter-form-status"
            options={partnerStatusOptions}
            placeholder="Account status"
            value={searchTerms.businessStatuses}
            onChange={(value) => {
              setSearchTerms({
                ...searchTerms,
                businessStatuses: value,
              });
            }}
          />
        </Col>
        <Col md={SEARCH_COL_SIZE}>
          <Select
            allowClear
            data-testid="partner-filter-form-state"
            placeholder="Partner ID card"
            options={partnerAccountStatusOptions}
            value={searchTerms.isPartnerIDCardCompleted}
            onChange={(value) => {
              setSearchTerms({
                ...searchTerms,
                isPartnerIDCardCompleted: value,
              });
            }}
            style={{ width: '100%' }}
          />
        </Col>
        <Col md={SEARCH_COL_SIZE + 1}>
          <Select
            allowClear
            data-testid="partner-filter-form-syncERP"
            placeholder="ERP synchronisation"
            options={partnerERPSyncOptions}
            value={searchTerms.isERPSyncOK}
            onChange={(value) => {
              setSearchTerms({ ...searchTerms, isERPSyncOK: value });
            }}
            style={{ width: '100%' }}
          />
        </Col>
        <Col>
          <Button
            shape="round"
            icon={<CloseOutlined />}
            onClick={handleReset}
            data-testid="partner-filter-reset-button"
          >
            Reset all Filters
          </Button>
        </Col>
      </Row>
    </form>
  );
};
