import { create } from 'zustand';

import { IModifiedPartner } from '../models/partner';
import { PARTNER_TYPES, PartnerStatus, PartnerTypes } from '../types/partner';

export type TLPPartnersSortBy = 'name' | 'createdAt' | 'updatedAt';

interface ILPPartnersStore {
  rowData: IModifiedPartner[];
  pageSize: number;
  offset: number;
  sortBy: TLPPartnersSortBy;
  order: 'ASC' | 'DESC';
  defaultSort: {
    sortBy: TLPPartnersSortBy;
    order: 'ASC' | 'DESC';
  };
  error: string | boolean;
  loading: boolean;
  count: number;
  dispatch: (args: TLPPartnersReducerInput) => void;
  total: number;
  searchTerms: {
    id: string;
    name: string;
    businessTypes: PartnerTypes | '';
    businessStatus: PartnerStatus | '';
  };
}

type TLPPartnersReducerInput =
  | {
      type: 'updateRowData';
      args: { rowData: any[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { pageSize: number; offset: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: TLPPartnersSortBy;
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'updateDefaultSort';
    }
  | {
      type: 'reset';
    }
  | {
      type: 'updateSearchTerms';
      args: {
        id: string;
        name: string;
        businessTypes: PartnerTypes | '';
        businessStatus: PartnerStatus | '';
      };
    };

const lpPartnersReducer = (
  state: ILPPartnersStore,
  input: TLPPartnersReducerInput
) => {
  switch (input.type) {
    case 'updateRowData':
      return { ...state, rowData: input.args.rowData };
    case 'updateError':
      return { ...state, error: input.args.error };
    case 'updateLoading':
      return { ...state, loading: input.args.loading };
    case 'updatePagination':
      return {
        ...state,
        pageSize: input.args.pageSize,
        offset: input.args.offset,
      };
    case 'updateSorting':
      return { ...state, sortBy: input.args.sortBy, order: input.args.order };
    case 'updateCount':
      return { ...state, count: input.args.count };
    case 'updateTotal':
      return { ...state, total: input.args.total };
    case 'updateDefaultSort':
      return {
        ...state,
        defaultSort: { sortBy: state.sortBy, order: state.order },
      };
    case 'updateSearchTerms':
      return {
        ...state,
        searchTerms: input.args,
        offset: 0,
      };
    case 'reset':
      return INITIAL_STATE;
  }
};

const INITIAL_STATE: Omit<ILPPartnersStore, 'dispatch'> = {
  rowData: [],
  error: '',
  loading: false,
  pageSize: 50,
  offset: 0,
  sortBy: 'updatedAt',
  order: 'DESC',
  defaultSort: {
    sortBy: 'updatedAt',
    order: 'DESC',
  },
  count: 0,
  total: 0,
  searchTerms: {
    id: '',
    name: '',
    businessTypes: `${PARTNER_TYPES.PRO},${PARTNER_TYPES.TEST}` as
      | PartnerTypes
      | '',
    businessStatus: '',
  },
};

const useLPPartnersStore = create<ILPPartnersStore>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input) => set((state) => lpPartnersReducer(state, input)),
}));

export default useLPPartnersStore;
